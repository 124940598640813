<template>
  <main class="page-container" id="sme-noticeinfo-view">
    <div class="page-component">
      <div class="page-header blind">
        <div class="page-component">
          <div class="page-header-title">
            <h2 class="title">소상공인 뉴스</h2>
          </div>
          <div class="page-header-hashtags">
            <p class="text">{{ tags }}</p>
          </div>
        </div>
      </div>
      <div class="page-body">
        <section class="post-view-wrapper">
          <header class="post-view-header">
            <div class="header-category">
              <div class="badge">
                <span class="badge-text">{{ plygrndCtgr }}</span>
              </div>
            </div>
            <h3 class="header-title">{{ plygrndTit }}</h3>
            <p class="header-tags">{{ tags }}</p>
          </header>
          <div class="post-view-body">
            <div class="gachi-view" v-html="plygrndCn"></div>
          </div>
        </section>
        <div class="page-buttons" data-align="center">
          <a href="javascript:">
          <button v-if="atchFilePtUrl" class="btn btn-lg btn-secondary" :disabled="downing" @click="clickDownload">
            <span class="text">{{downing ? '다운로드 중...': '첨부파일' }}<br>[{{ atchFileNm }}]</span>
          </button>
<!--          <router-link-->
<!--              custom-->
<!--              to="/playground"-->
<!--              v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <div class="list-btn-cont">-->
<!--              <button class="btn btn-lg btn-primary" @click="goList">-->
<!--                <span class="text">목록</span>-->
<!--              </button>-->
<!--            </div>-->
          </a>
          <div v-if="isMobile" class="page-buttons" data-align="center" style="flex-direction: row;width: 100%;margin: auto">
            <a href="javascript:">
              <next-prev-button
                  route-name="PlaygroundView"
                  param-key="plygrndId"
                  queryKey="ctgrCode"
                  :query="$route.query.ctgrCode"
                  :pre-id="preId"
                  :next-id="nextId"
              ></next-prev-button>
            </a>
          </div>
          <div v-else class="page-buttons" data-align="center" style="flex-direction: row;width: 900px;margin: auto">
            <a href="javascript:">
              <next-prev-button
                  route-name="PlaygroundView"
                  param-key="plygrndId"
                  queryKey="ctgrCode"
                  :query="$route.query.ctgrCode"
                  :pre-id="preId"
                  :next-id="nextId"
              ></next-prev-button>
            </a>
          </div>
          <div v-if="isMobile" class="list-btn-cont" style="width: 120px;position: absolute;left: calc(50% - 50px);top: calc(100% - 100px);">
            <button class="btn btn-lg btn-primary" @click="goList">
              <span class="text">목록</span>
            </button>
          </div>
          <div v-else class="list-btn-cont" style="width: 120px;position: absolute;left: calc(50% - 60px);top: calc(100% - 60px);">
            <button class="btn btn-lg btn-primary" @click="goList">
              <span class="text">목록</span>
            </button>
          </div>
<!--          </router-link>-->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {ACT_DOWN_ATTACH_PLAYGROUND3, ACT_GET_PLAYGROUND} from '../../../store/_act_consts';
import {getItem, lengthCheck, queryToValue, viewTags} from '../../../assets/js/utils';
import {viewCount} from '../../../assets/js/func';
import NextPrevButton from '../../../components/common/NextPrevButton';
import {mapGetters} from "vuex";

export default {
  name: 'PlaygroundView',
  components:{
    NextPrevButton
  },
  watch:{
    $route(to){
      this.plygrndId = queryToValue(to.params.plygrndId, true, 0);
      this.getPlayground();
    }
  },
  data:()=> ({
    plygrndId: 0,
    plygrndCtgr: '',
    plygrndTit: '',
    tags: '',
    plygrndCn: '',
    atchFileNm:'',
    atchFilePtUrl:'',
    downing: false,
    preId: 0,
    nextId: 0
  }),
  computed: {
    ...mapGetters('common', ['isMobile'])

  },
  created() {
    this.plygrndId = this.$route.params.plygrndId;
    viewCount(this.plygrndId, 'plygrnd');
    this.getPlayground();
  },
  methods: {
    goList(){
      this.$router.push({name: 'Playground', query:{ctgrCode:this.$route.query.ctgrCode}});
    },
    getPlayground() {
      this.$store.dispatch(`information/${ACT_GET_PLAYGROUND}` , {
        plygrndId: this.plygrndId,
        plygrndCtgrDcd: this.$route.query.ctgrCode
      })
      .then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          this.plygrndId = item.plygrndId;
          this.plygrndCtgr = item.plygrndCtgr;
          this.plygrndTit = item.plygrndTit;
          this.tags = viewTags(item, 'plygrndTag');
          this.plygrndCn = item.plygrndCn;
          this.atchFileNm = item.atchFileNm;
          this.atchFilePtUrl = item.atchFilePtUrl;
          this.preId = item.preId;
          this.nextId = item.nextId;
        }else{
          this.plygrndId = 0;
        }
      }).catch(e=>{
        console.error(e);
        this.plygrndId = 0;
      })
    },
    clickDownload(){
      // window.open(`${this.atchFilePtUrl}`);
      this.downing = true;
      this.$store.dispatch(`community/${ACT_DOWN_ATTACH_PLAYGROUND3}`, {
        plygrndId: this.plygrndId,
        fileName: this.atchFileNm
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.atchFileNm)
        document.body.appendChild(link)
        link.click()
        this.downing = false;
      }).catch(err => {
        console.log(err)
        this.downing = false;
      })
    }

  }
};
</script>
